@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

@media only screen and (max-width: 768px) {

  html,
  body,
  #root {
    height: 100%;
    margin: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    background: #eee;
  }
}

@media only screen and (min-width: 768px) {

  html,
  body,
  #root {
    height: 100%;
    margin: 0;
    overflow-x: hidden;
    overflow-y: hidden;
    background: #F7F9FB;
  }
}

:root {
  --ant-primary-color: #11263C;
  --antd-wave-shadow-color: #11263C;
  --ant-primary-color-hover: #ff7f00;
  --ant-primary-color-active: #11263C;
  --ant-primary-5: #ff7f00;
  --ant-primary-color-outline: transparent;
  --ant-selected-color: #ebf5ff;
  --ant-avatar-color: #dad4ff;
  --ant-hover-color: #e5ffea;
  --ant-gradient-color: #8883FF;
  --ant-box-color: #EBE8FF;
}

* {
  font-family: 'Inter', sans-serif !important;
}

*:focus {
  outline: none;
}

.middle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.align-center {
  align-items: center !important;
}

.justify-center {
  justify-content: center;
}

.text-center {
  text-align: center !important;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.ant-spin-nested-loading {
  height: 100%;
  z-index: 1;
}

.ant-spin-container {
  height: 100%;
}

.ant-spin-nested-loading>div>.ant-spin {
  max-height: unset !important;
}

.icon-prefix {
  color: rgba(0, 0, 0, 0.25) !important;
  margin-right: 5px;
}

.ant-form-item-explain-error {
  font-size: 12px !important;
  margin-bottom: 10px;
  margin-top: 5px;
}

.h-100 {
  height: 100%;
}

.ant-tooltip-inner {
  font-size: 12px !important;
}

.ant-form-item-explain {
  font-size: 12px !important;
  margin-top: 10px;
  margin-bottom: 10px;
}

.padding-10 {
  padding: 10px;
}

.setting-wrapper {
  height: 100%;
  overflow-y: auto;
}

.ant-table-cell {
  font-size: 12px;
}

.ant-card {
  border-radius: 10px;
}

.ant-menu-title-content {
  font-size: 12px !important;
}

::placeholder {
  font-size: 12px;
}

a>span {
  font-size: 12px;
}

.card-product-filter>.ant-card-body {
  display: flex;
  align-items: center;
  width: 100%;
}

.ant-select-selection-placeholder {
  font-size: 12px !important;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: none !important;
}

.ant-btn {
  font-size: 12px !important;
}

.ant-divider-inner-text {
  font-size: 12px;
}

.ant-layout {
  background-color: #F7F9FB !important;
}
.ant-menu-sub {
  background: #fff !important;
}
.ant-layout-header {
  height: 55px !important;
  line-height: unset !important;
}

.ant-layout-header>span {
  font-size: 15px !important;
}

.ant-form-item-label>label {
  font-size: 12px !important;
}

.card-form {
  margin: 20px !important;
}

.card-form>.ant-card-body {
  padding: 10px 0 0 0;
}

.shadow {
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
}

.ant-card {
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  border-radius: .428rem;
}

.ant-carousel .slick-dots-bottom {
  bottom: -5vh;
}

.container-mapper {
  width: 100%;
  position: relative;
}

.container-mapper div {
  margin: auto;
}

.tooltip {
  position: absolute;
  color: #fff;
  padding: 10px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 5px;
  pointer-events: none;
  z-index: 1000;
}

.ant-form-item-extra {
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 5px;
}

span.ant-radio+* {
  font-size: 12px;
}

.ant-steps-item-title {
  font-size: 12px !important;
}

.ant-ribbon-text {
  font-size: 12px;
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

::-webkit-scrollbar-thumb {
  background-color: darkgray;
}

.selector-car .ant-select-selector {
  height: 80px !important;
}

.ant-menu-horizontal { 
  border-bottom: unset !important;
}

.ant-menu-item-selected {
  color: #fff;
}

.link:hover {
  color: var(--ant-primary-color);
}

.ant-menu-light {
  color: rgba(17, 38, 60, 1) !important;
  font-weight: 500;
}
.ant-menu-item-selected {
  background-color: rgba(17, 38, 60, 0.05) !important;
}

.ant-menu-item-icon {
  font-weight: bold;
}